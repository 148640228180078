const colors = {
  green: {
    '100': '#C6F6D5',
    '400': '#48BB78',
    '500': '#38A169',
    '600': '#25855A',
  },
  blue: {
    '50': '#EBF8FF',
    '100': '#BEE3F8',
    '200': '#90CDF4',
    '300': '#63B3ED',
    '400': '#4299E1',
    '500': '#3182CE',
    '600': '#2B6CB0',
    '700': '#2C5282',
    '800': '#2A4365',
    '900': '#1A365D',
  },
  red: {
    '500': '#E53E3E',
    '100': '#FED7D7',
  },
  orange: {
    '100': '#FEEBCB',
  },
  gray: {
    '50': '#F7FAFC', // <-
    '100': '#EDF2F7',
    '200': '#E2E8F0',
    '300': '#CBD5E0',
    '400': '#A0AEC0',
    '500': '#718096',
    '600': '#4A5568',
    '700': '#2D3748',
    '800': '#1A202C',
    '900': '#171923',
  },
  black: '#101112',
  white: '#ffffff',
  blackAlpha: {
    '50': 'RGBA(16, 17, 18, 0.04)',
    '100': 'RGBA(16, 17, 18, 0.06)',
    '200': 'RGBA(16, 17, 18, 0.08)',
    '300': 'RGBA(16, 17, 18, 0.16)',
    '400': 'RGBA(16, 17, 18, 0.24)',
    '500': 'RGBA(16, 17, 18, 0.36)',
    '600': 'RGBA(16, 17, 18, 0.48)',
    '700': 'RGBA(16, 17, 18, 0.64)',
    '800': 'RGBA(16, 17, 18, 0.80)',
    '900': 'RGBA(16, 17, 18, 0.92)',
  },
  primary: {
    '50': '#f5e1e7',
    '100': '#e8b5c3',
    '200': '#d8869c',
    '300': '#c85a78',
    '400': '#bd3e5f',
    '500': '#b22748',
    '600': '#a32445',
    '700': '#8f2141',
    '800': '#7a1e3c',
    '900': '#561931',
  },
  secondary: {
    '50': '#fbf6e1',
    '100': '#f5e7b3',
    '200': '#efd883',
    '300': '#e9ca53',
    '400': '#e6bd33',
    '500': '#e2b321',
    '600': '#e2a61b',
    '700': '#e19517',
    '800': '#e08613',
    '900': '#dd6a0e',
  },
  github: '#171923',
  telegram: '#2775CA',
  linkedin: '#1564BA',
  discord: '#9747FF',
  slack: '#1BA27A',
  twitter: '#63B3ED',
  opensea: '#2081E2',
  facebook: '#4460A0',
  medium: '#231F20',
  reddit: '#FF4500',
};

export default colors;
// cd /root/maa-laxmi/blockscout/docker
// 1003  make stop
// 1004  docker ps -a
// 1005  docker stop 4821427fe5a1 8ae82777036f 23f68f27c821
// 1006  docker rm 4821427fe5a1 8ae82777036f 23f68f27c821
// 1007  docker rmi $(docker images -a)
// 1008 make start
